import React  from 'react';
import { Link } from 'react-router-dom';
import "./Navbar.css"
import coin1 from "../assets/star.png"



const Navbar = () => {
  return (
    <>
  <div id="container">
  
  
      <nav id="navbar">
      <div className='mb-2' id="main">
      <div className='mt-2 rounded text-white  ' id="btn2"><span id="btn3"><img src={coin1} alt="icon"  /> </span><span > Total Referral Earnings </span><div className='ms-4 mb-2'>1950.00 MLD</div> </div>
      </div>
       
      <div>
        <Link to="leader"><button className='mt-2 rounded' id="btn"><span className='bi bi-person '></span> My Profile </button></Link>
      </div>
      <div>
    <Link to="leader"> <button className='mt-2 rounded' id="btn"> <span className='bi bi-reception-3'></span> Leaderboards </button></Link>
      </div> 
      <div>
       <Link to="leader"> <button className='mt-2 rounded' id="btn"><span className='bi bi-gift-fill'></span> My Referrals </button></Link>
      </div>
      <div>
       <Link to="leader"> <button className='mt-2 rounded' id="btn"><span className='bi bi-journals '></span>Reports</button></Link>
      </div>
      <div className='mt-4'>
        <hr className='ms-4 me-4' />
      </div>
      <div className="info">
      <div>
     <span className='bi bi-info-circle'></span> Other information 
      </div>
      <div>
        <p>Payments</p>
      </div>
      <div>
        <p>Referral Policy</p>
      </div>
      <div>
        <p>Terms and conditions</p>
      </div>
      </div>
     
    </nav>
   </div>
   
  
   </>
  );
};

export default Navbar;
