import React from 'react'
import Metaspace from "../assets/Metaspace.jpg"
import Avatar1 from "../assets/man.png"
import "./Header.css"
const Header = () => {
  return (
    <>
    <div id="head" className='container-fluid'>
        <div>
            <img src={Metaspace} alt=""width="20px" />
        </div>
        <div>
          <div className='input-group'>
          <input type="text" className='mt-2' id="input1" placeholder='WALLET 7120.00 MLD' />
            <div>
            <button className='mt-2 '  id="btn1">Deposite</button>
            </div>
            <div className="icon">
                <img src={Avatar1}  alt='icon' className='me-4' />
            </div>
          </div>
        </div>
    </div>
      
    </>
  )
}

export default Header
